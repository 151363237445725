import React from "react";

const BgContainer = ({ text }) => {
  return (
    <div
      className="w-100 d-flex align-items-center justify-content-center py-2 py-md-3 headerText my-3 my-md-5 text-center"
      style={{ backgroundColor: "#F5F5FF" }}
    >
      {text}
    </div>
  );
};

export default BgContainer;
